<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout },
  data() {
    return {
      plan: null,
      type: null,
      loading: false,

      newline: {
        zipcode: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
      },
      portability: {
        number: "",
      }
    };
  },
  validations: {
    newline: {
      zipcode: { required },
      address: { required },
      number: { required },
      complement: {},
      district: { required },
      city: { required },
      state: { required },
    },
    portability: {
      number: { required },
    }
  },
  methods: {
    getPlan() {
      api.get("internet/plans/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.plan = response.data.plan;
        }
      });
    },
    contractPlan() {
      this.loading = true;

      if (this.type == 'newline') {
        this.$v.newline.$touch();

        if (this.$v.newline.$invalid) {
          return;
        } else {
          api
            .post("internet/subscribers", {
              plan: this.plan.id,
              type: 'newline',
              zipcode: this.newline.zipcode,
              address: this.newline.address,
              number: this.newline.number,
              complement: this.newline.complement,
              district: this.newline.district,
              city: this.newline.city,
              state: this.newline.state
            })
            .then((response) => {
              if (response.data.status == "success") {
                if (response.data.order &&
                    response.data.order.id) {
                  this.$router.push("/store/order/" + response.data.order.id);
                } else {
                  this.$router.push("/internet/subscriber");
                }
              } else {
                this.$noty.error(response.data.message);
              }

              this.loading = false;
            });
        }
      } else if (this.type == 'portability') {
        this.$v.portability.$touch();

        if (this.$v.portability.$invalid) {
          return;
        } else {
          api
            .post("internet/subscribers", {
              plan: this.plan.id,
              type: 'portability',
              number: this.portability.number
            })
            .then((response) => {
              if (response.data.status == "success") {
                if (response.data.order &&
                    response.data.order.id) {
                  this.$router.push("/store/order/" + response.data.order.id);
                } else {
                  this.$router.push("/internet/subscriber");
                }
              } else {
                this.$noty.error(response.data.message);
              }

              this.loading = false;
            });
        }
      }



    },
    searchCep() {
      if (this.newline.zipcode.length == 9) {
        api.get("cep/" + this.newline.zipcode).then((response) => {
          if (response.data.city !== "") {
            if (response.data.address) {
              this.newline.address = response.data.address;
            }
            if (response.data.district) {
              this.newline.district = response.data.district;
            }
            this.newline.city = response.data.city;
            this.newline.state = response.data.state;
          }
        });
      }
    },
  },
  mounted() {
    this.getPlan();
  },
  watch: {
    type: function () {
      this.newline.zipcode = "";
      this.newline.address = "";
      this.newline.number = "";
      this.newline.complement = "";
      this.newline.district = "";
      this.newline.city = "";
      this.newline.state = "";
      this.portability.number = "";
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Planos") }}</h4>
        </div>
      </div>
    </div>

    <div v-if="!plan" class="text-center">
      <b-spinner small variant="default"></b-spinner>
    </div>
    <div v-else-if="plan && plan.name">
      <div class="card">
        <div class="card-header bg-default rounded-top py-3">
          <p class="m-0 text-white">Plano</p>
          <h4 class="plan-name m-0 text-white">{{ plan.name }}</h4>
        </div>
        <div class="card-body">
          <h4 class="plan-price">{{ plan.value | currency }}/mês</h4>

          <div v-for="(description, index) in plan.features" :key="index">
            <i class="bx bx-plus"></i> {{ description }}
          </div>

          <div class="my-4">
            <p>O que deseja fazer:</p>
            <b-form-group>
              <b-form-radio v-model="type" name="type" value="newline" size="lg">Quero uma linha nova</b-form-radio>
            </b-form-group>
          </div>

          <hr class="mb-4">

          <div v-if="type == 'newline'" class="row">
            <div class="col-md-8">
              <h5>Informe o seu endereço para a entrega do seu novo chip</h5>
              <p class="mb-4">O prazo para entrega do chip é de 25 dias</p>
              <b-form-group
                id="newline.zipcode"
                label="CEP"
                label-for="newline.zipcode"
              >
                <b-form-input
                  v-on:blur="searchCep()"
                  id="newline.zipcode"
                  v-model="newline.zipcode"
                  type="text"
                  :class="{ 'is-invalid': $v.newline.zipcode.$error }"
                  autocomplete="off"
                  v-mask="'#####-###'"
                ></b-form-input>
                <div v-if="$v.newline.zipcode.$error" class="invalid-feedback">
                  <span v-if="!$v.newline.zipcode.required"
                    >O CEP é obrigatório.</span
                  >
                  <span v-if="!$v.newline.zipcode.invalid"
                    >O CEP informado é invalido.</span
                  >
                </div>
              </b-form-group>
              <b-form-group
                id="newline.address"
                label="Endereço"
                label-for="newline.address"
              >
                <b-form-input
                  id="newline.address"
                  v-model="newline.address"
                  type="text"
                  :class="{ 'is-invalid': $v.newline.address.$error }"
                  autocomplete="off"
                ></b-form-input>
                <div v-if="$v.newline.address.$error" class="invalid-feedback">
                  <span v-if="!$v.newline.address.required"
                    >O endereço é obrigatório.</span
                  >
                </div>
              </b-form-group>
              <b-form-group
                id="newline.number"
                label="Número"
                label-for="newline.number"
              >
                <b-form-input
                  id="newline.number"
                  v-model="newline.number"
                  type="text"
                  :class="{ 'is-invalid': $v.newline.number.$error }"
                  autocomplete="off"
                ></b-form-input>
                <div v-if="$v.newline.number.$error" class="invalid-feedback">
                  <span v-if="!$v.newline.number.required"
                    >O número é obrigatório.</span
                  >
                </div>
              </b-form-group>
              <b-form-group
                id="newline.complement"
                label="Complemento"
                label-for="newline.complement"
              >
                <b-form-input
                  id="newline.complement"
                  v-model="newline.complement"
                  type="text"
                  :class="{ 'is-invalid': $v.newline.complement.$error }"
                  autocomplete="off"
                ></b-form-input>
                <div
                  v-if="$v.newline.complement.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.newline.complement.required"
                    >O complemento é obrigatório.</span
                  >
                </div>
              </b-form-group>
              <b-form-group
                id="newline.district"
                label="Bairro"
                label-for="newline.district"
              >
                <b-form-input
                  id="newline.district"
                  v-model="newline.district"
                  type="text"
                  :class="{ 'is-invalid': $v.newline.district.$error }"
                  autocomplete="off"
                ></b-form-input>
                <div v-if="$v.newline.district.$error" class="invalid-feedback">
                  <span v-if="!$v.newline.district.required"
                    >O bairro é obrigatório.</span
                  >
                </div>
              </b-form-group>
              <b-form-group
                id="newline.city"
                label="Cidade"
                label-for="newline.city"
              >
                <b-form-input
                  id="newline.city"
                  class="bg-soft-info"
                  v-model="newline.city"
                  type="text"
                  :class="{ 'is-invalid': $v.newline.city.$error }"
                  autocomplete="off"
                  readonly="readonly"
                ></b-form-input>
                <div v-if="$v.newline.city.$error" class="invalid-feedback">
                  <span v-if="!$v.newline.city.required"
                    >A cidade é obrigatória.</span
                  >
                </div>
              </b-form-group>
              <b-form-group
                id="newline.state"
                label="Estado"
                label-for="newline.state"
              >
                <b-form-input
                  id="newline.state"
                  class="bg-soft-info"
                  v-model="newline.state"
                  type="text"
                  :class="{ 'is-invalid': $v.newline.state.$error }"
                  autocomplete="off"
                  readonly="readonly"
                  v-mask="'XX'"
                ></b-form-input>
                <div v-if="$v.newline.state.$error" class="invalid-feedback">
                  <span v-if="!$v.newline.state.required"
                    >O estado é obrigatório.</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>

          <div v-if="type == 'portability'" class="row">
            <div class="col-md-8">
              <h5>Informe o seu número para ser realizado a portabilidade</h5>
              <p class="mb-4">O prazo para portabilidade é de 7 dias úteis</p>
              <b-form-group
                id="portability.number"
                label="Seu número"
                label-for="portability.number"
              >
                <b-form-input
                  id="portability.number"
                  v-model="portability.number"
                  type="text"
                  :class="{ 'is-invalid': $v.portability.number.$error }"
                  autocomplete="off"
                  v-mask="'(##) #####-####'"
                  size="lg"
                ></b-form-input>
                <div v-if="$v.portability.number.$error" class="invalid-feedback">
                  <span v-if="!$v.portability.number.required"
                    >O número é obrigatório.</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>

          <button
            class="mt-3 btn btn-default px-4"
            v-on:click="contractPlan()"
            :disabled="
              !type || loading ||
              type == 'newline' && $v.newline.error ||
              type == 'portability' && $v.portability.error
            "
          >
            Contratar
            <b-spinner
              v-if="loading"
              small
              class="ml-2 align-middle"
              variant="white"
              role="status"
            ></b-spinner>
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.plan-price {
  border-bottom: 5px solid #555;
  padding-bottom: 3px;
}
</style>
